import React, { Component } from 'react';
import './Skills.css';

class Skills extends Component {
  render() {
    return (
      <div className="Jumbotron">  
            <div className="banner-text">
                <h3>Skills</h3>
                <p>Tech: HTML/CSS | Javascript |  Reactjs | Solidity | Python | Java | SQL </p>
                <p>Business: US GAAP Accounting | Financial Analysis |  Insurance Industry Knowledge |</p>
            </div>
      </div>
    )
  }
}
export default Skills;