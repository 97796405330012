import React, { Component } from 'react';
import Switch from 'react-switch';
import VMStart from './VMStart';
import VMStop from './VMStop';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ToggleSwitch2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: ((this.props.initialState === 'PowerState/running') || (this.props.initialState === 'PowerState/starting') ? true : false),
            ResourceGroup: this.props.ResourceGroup,
            Name: this.props.Name,
            Refresh: this.props.Refresh,
            Loading: this.props.Loading
        };
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange = (checked) => {
        this.setState({ checked });
        //console.log(checked)
        checked === true ? VMStart(this.state.ResourceGroup, this.state.Name) : VMStop(this.state.ResourceGroup, this.state.Name);
        //this.setState({ Loading: true });
       // setTimeout(() => {
        //    this.state.Refresh // if brackets added whole page refreshes. same like: window.location.reload()
        //    this.props.Refresh 
            //this.setState({ Loading: false });
            //console.log(this.state.Loading)
        //}, 4000);
       // this.state.Refresh 
    }

    render() {
        const textSwitch = {
            display: "flex",
            justifyContent: "center",
            alignitems: "center",
            height: "100%",
            fontSize: 15,
            color: "#fff",
            paddingRight: 2
        }
        return (
            <label>
                <Switch className="react-switch" onChange={this.handleChange} checked={(this.state.checked)} checkedIcon={<div style={textSwitch}>on</div>} uncheckedIcon={<div style={textSwitch}>off</div>} />
                {//this.state.Loading && <i className="fas fa-spinner fa-spin"></i>
                }
            </label>
        );
    }
}
export default ToggleSwitch2;