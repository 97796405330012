import React, {Component} from 'react';
import Navbar from 'react-bootstrap/Navbar';


class Header extends Component{
    render() {
        const navbar = {backgroundColor: '#4080bf'};
        return(
          

            <div>
                <Navbar fixed="top" style={navbar}>
                    <Navbar.Brand href="#home">
                    <img
                        src="https://i.imgur.com/6dXedZd.jpg"
                        width="90"
                        height="30"
                        className="d-inline-block align-top"
                        alt="Softsite"
                    />
                    </Navbar.Brand>
                </Navbar>
            <br />
            </div>
        );
    }
}

export default Header;