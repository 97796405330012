import React, {Component} from 'react';
import Header from '../pages/VMPortal/Header.js';
import Table from '../pages/VMPortal/Table.js';
import NavigationBar from '../Components/NavigationBar.js';
import Footer from '../Components/Footer.js';
import './VMPortalDemo.css';

class VMPortalDemo extends Component {
    render(){
        return(
            <div>
                <NavigationBar />
                    <br></br>
                    <br></br>
                    <h5 >Below is a DemoVersion of an Intranet Virtual Machine Portal that I built to control the clients Azure Virtual Machines. The live version requires Azure AD Authentication and is only accessible by the clients employees.</h5>
                <Table />
                <Footer />
            </div>
        );
        };
    }
    export default VMPortalDemo;