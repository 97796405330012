import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './Footer.css';

class Footer extends Component {
    render() {
        return(
            <footer className="footer">
                <div className="container-fluid text-center">
                    <span className="text-muted">
                        <br></br>
                        <h3>Contact </h3>
                        <h5><i class="fas fa-envelope"></i> Email: </h5> <a href="mailto:info@candreiaweb3.com"><b>info@candreiaweb3.com</b></a>
                        <h3>Connect</h3>
                        <a href="https://www.facebook.com/candreiaweb3/"><i class="fab fa-facebook-f fa-2x"></i></a>
                        <a href="https://twitter.com/CandreiaW"><i class="fab fa-twitter fa-2x"></i></a>
                        <a href="https://www.linkedin.com/in/michael-candreia-a5a7a1b/"><i class="fab fa-linkedin-in fa-2x"></i></a>
                        <br></br>
                        Candreia Web3 &#169; {new Date().getFullYear()}
                    </span>
                </div>
            </footer>
        );
    }
}

export default Footer;


