import React, {Component} from 'react';
import NavigationBar from '../Components/NavigationBar.js';
import Footer from '../Components/Footer.js';

class Error extends Component {
    render(){
        return(
            <div>
             <NavigationBar />
                <div>
                <p>Error  -  Path does not exist!</p>
                </div>
                <br />
            <Footer />
            </div>
        );
        };
    }
    export default Error;