import React, { Component } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import './App.css';
import Home from './pages/Home.js';
import NavigationBar from './Components/NavigationBar.js';
import Footer from './Components/Footer.js';
import About from './pages/About.js';
import Projects from './pages/Projects.js';
import Error from './pages/Error.js'
import VMPortalDemo from './pages/VMPortalDemo.js';

class App extends Component {

  render() {
 
    return (
      
      <BrowserRouter>

      <div >
            <Switch> 
                <Route exact path="/" component={Home} />
                <Route  path="/about" component={About} />      
                <Route  path="/projects" component={Projects} />     
                <Route  path="/VMPortalDemo" component={VMPortalDemo} />  
                <Route component={Error} /> 
            </Switch>
        </div>

      </BrowserRouter>

    );
  }
}

export default App;
