
import axios from 'axios';


function VMStart(_resourceGroup, _vmName){
    // const VMdata={
    //     resourceGroup: _resourceGroup,
    //     vmName: _vmName  };
  
    // axios.post('slkjf/asdflja/', VMdata)
    //     .then(response => {console.log(response)});
    //     console.log(VMdata);
    console.log("VMd Start!");
  }

export default VMStart;