import React, {Component} from 'react';
import './About.css';
import NavigationBar from '../Components/NavigationBar.js';
import Footer from '../Components/Footer.js';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

class About extends Component {
        render() {
            return(
                <div >         
                    <NavigationBar />
                        <div className="container">
                        <br />
                        <br />
                        <h2 align="center" >About</h2> 
                        <br />
                            <div className="row justify-content-center align-items-center">                             
                                <Card style={{ width: '18rem' }}>   
                                <Card.Img variant="top" src={require("../Img/_D4Y0661_cropped_sa.jpg")}/>
                                <Card.Body>
                                <Card.Title>Michael Candreia, Founder</Card.Title>
                                <Card.Text>
                                <p>After completing his Bachelor of Science degree in Molecular Biology & Biochemistry, Michael started his career at Swiss Re, a leading provider of reinsurance and other insurance-based forms of risk transfer. With his passion for both finance and technology, Michael decided to leave his position as an Accounting Manager at Swiss Re to learn more about the Blockchain space. This has led him to work on various decentralised applications built on the Ethereum Blockchain, including EasyHODL.</p>
                                </Card.Text>
                                {
                                //<Button variant="primary">Projects</Button>
                                }
                                </Card.Body>
                                </Card>;                
                            </div>
                            </div>
                        <br />
                        <br />
                    <Footer />
                </div>
            );
        }
}

export default About;