import React from 'react';
import NavigationBar from '../Components/NavigationBar.js';
import Footer from '../Components/Footer.js';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
//import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import image_one from "../Img/iStock-933615014.jpg";
import image_two from "../Img/toggle2.jpg";
import image_three from "../Img/1443603140.png";
import image_four from "../Img/Tapa Food Logo final jpeg.jpg";
import image_five from "../Img/datacollection.jpg";
import image_six from "../Img/iStock963459054.jpg";
import { Link } from 'react-router-dom';
const CYTaxiLink = "http://cytaxi24h.com";
const EasyHODL = "http://easyhodl.com";
const VMPortal = "/VMPortalDemo";
const Tapafood = "http://tapafood.com";
const Webscraping = "http://michaelcan.pythonanywhere.com/";
const Escrow256 = "http://escrow256.com"

const styles = {
  card: {
    maxWidth: 345,
    margin: 20
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
  },
};

function ImgMediaCard(props) {
  const { classes } = props;
  return (
    <div>
      <NavigationBar/>
      <br></br>
      
      <Grid container justify="center" style={{"padding-top":"10px"}}>
  
        <header className="container-fluid" >
        <h3 align="center">Completed Projects</h3>
        </header>



      <Card className={classes.card}>
        <CardMedia
          component="img"
          alt="VM Portal"
          className={classes.media}
          height="200"
          image={image_two}
          title="VM Portal"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h4">
          VM Portal
          </Typography>
          <Typography component="p">
          An intranet Portal based on ReactJS allowing users to switch on or off Azure Virtual Machines 
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" href={VMPortal} >
                  See More
          </Button>
        </CardActions> 
      </Card>


      <Card className={classes.card}>
          <CardMedia
            component="img"
            alt="CY Taxi 24h"
            className={classes.media}
            height="200"
            image={image_three}
            title="CY Taxi 24h"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h4">
              Taxi Service Homepage
            </Typography>
            <Typography component="p">
              A single page application for a small business owner using ReactJS
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" color="primary" href={CYTaxiLink}>
              See More
            </Button>
          </CardActions> 
      </Card>
      <header className="container-fluid" >
        <h3 align="center">Work in progress</h3>
        </header>
      <Card className={classes.card}>
          <CardMedia
            component="img"
            alt="Kobitrader"
            className={classes.media}
            height="200"
            image={image_five}
            title="Kobitrader - Webscraping Online Data"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h4">
              Webscraping App
            </Typography>
            <Typography component="p">
              A webpage for a private person looking to scrape data automatically from various websites.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" color="primary" href={Webscraping}>
              See More
            </Button>
          </CardActions> 
      </Card>

      <Card className={classes.card}>
          <CardMedia
            component="img"
            alt="EasyHODL"
            className={classes.media}
            height="200"
            image={image_one}
            title="EasyHODL"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h4">
              EasyHODL
            </Typography>
            <Typography component="p">
              A decentralised App built on Ethereum to lock up your Ether for a predefined period of time.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" color="primary" href={EasyHODL}>
              See More
            </Button>
          </CardActions> 
      </Card>

      <Card className={classes.card}>
          <CardMedia
            component="img"
            alt="Escrow256"
            className={classes.media}
            height="200"
            image={image_six}
            title="Escrow256"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h4">
              Escrow256
            </Typography>
            <Typography component="p">
              A decentralised App built on Ethereum to provide escrow services for ether and ERC20 tokens.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" color="primary" href={Escrow256}>
              See More
            </Button>
          </CardActions> 
      </Card>
      {/* <Card className={classes.card}>
          <CardMedia
            component="img"
            alt="Wordpress"
            className={classes.media}
            height="200"
            image={image_four}
            title="Wordpress Installations/Modifications"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h4">
              Wordpress Installations/Modifications
            </Typography>
            <Typography component="p">
              Installing and making modifications to wordpress themes
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" color="primary" href={Tapafood}>
              See More
            </Button>
          </CardActions> 
      </Card> */}
     
      </Grid>
      <Footer/>
    </div>
  );
}

ImgMediaCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImgMediaCard);
