import React, { Component } from 'react';
import './AppVM.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToggleSwitch2 from './ToggleSwitch2';
//import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
//import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import LoadingOverlay from 'react-loading-overlay';
import VMData from './VMData.json';
import {adalApiFetch} from './AzureAdAuthConfig';
//import overlayFactory from 'react-bootstrap-table2-overlay';


class Table extends Component {
    constructor() {
        super();
        this.refresh = this.refresh.bind(this);
        this.state = {
            checked: false,
            error: false,
            loading: false,
            data: [],
            columns: [
                {
                    dataField: "Name",
                    text: "VM Name",
                    headerStyle: {
                        backgroundColor: '#C8C8C8'
                    },
                    sort: true,
                    filter: textFilter()
                },
                {
                    dataField: "Description",
                    text: "Description",
                    headerStyle: {
                        backgroundColor: '#C8C8C8'
                    },
                    sort: true,
                    filter: textFilter()
                },
                {
                    dataField: "Solution",
                    text: "Solution",
                    headerStyle: {
                        backgroundColor: '#C8C8C8'
                    },
                    sort: true,
                    filter: textFilter()
                },
                {
                    dataField: "AppVersion",
                    text: "AppVersion",
                    headerStyle: {
                        backgroundColor: '#C8C8C8'
                    },
                    sort: true,
                    filter: textFilter()
                },
                {
                    dataField: "Purpose",
                    text: "Purpose",
                    headerStyle: {
                        backgroundColor: '#C8C8C8'
                    },
                    sort: true,
                    filter: textFilter()
                },
                {
                    dataField: "ResourceGroup",
                    text: "ResourceGroup",
                    headerStyle: {
                        backgroundColor: '#C8C8C8'
                    },
                    sort: true,
                    filter: textFilter()
                },
                {
                    dataField: "State",
                    text: "State",
                    sort: true,
                    filter: textFilter(),
                    headerStyle: (colum, colIndex) => {
                        return { width: '185px', textAlign: 'center', backgroundColor: '#C8C8C8' };
                    }
                },
                {
                    dataField: 'Power',
                    text: 'On/Off',
                    headerStyle: {
                        backgroundColor: '#C8C8C8'
                    },
                    sort: true,
                    formatter: (cellContent, row) =>{
                       let loading  = this.state.loading;
                        return(
                        <div>
                            <ToggleSwitch2 initialState={row.State} ResourceGroup={row.ResourceGroup} Name={row.Name} Refresh={() => { this.refresh() }}></ToggleSwitch2>
                            <Button 
                                disabled={loading}
                                variant="default" 
                                className="buttonload"                                
                                onClick={() => {
                                    if(!loading) {this.refresh()} 
                                    //if(!loading) {this.refreshWithLoader()} 
                                    // this.setState({ loading: true })  //if this set true and brackets removed from refresh then loading overlay, but never ending                              
                                    // this.state.loading === true ? <img src={require('./giphy.gif')} /> : " ⟳  "                               
                                    }
                                }>
                               
                                {loading ? <i className="fa fa-spinner fa-spin fa-1x fa-fw"></i>: '⟳'}
                                                        
                            </Button>
                        </div>
                    )},
                },
            
            ]
        };

    };


    refreshWithLoader() {
        const url = "api/vm/list/";
        this.setState({ loading: true });
        this.setState({ data: VMData });

        setTimeout(() => {
            console.log(this.state.loading)
            // axios.get(url)
            //     .then(response => {
            //         console.log(((response.data)));
            //         this.setState({ data: JSON.parse(response.data) });
            //         console.log(this.state.loading)
            //     })
                // .catch(error => {
                //     console.log(error);
                //     this.setState({ loading: false });
                // })
            console.log('refreshing ')
            this.setState({ loading: false })
        },  2000);
    }

    refresh() {
        //const url = "api/vm/list/"; 
        console.log(this.state.loading)
        this.setState({ loading: true });
        this.setState({ data: VMData });
        console.log(this.state.loading)
        console.log('refreshed');
        this.setState({ loading: false });
        console.log(this.state.loading)
        {/* axios.get(url)
            .then(response => {
                console.log(((response.data)));
                this.setState({ data: JSON.parse(response.data) });
                this.setState({ loading: false });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            }) */}
        //  this.forceUpdate();
        //  this.setState(this.state);
    }
  
    
    componentDidMount() {
        const url = "api/vm/list/";
        this.setState({ loading: true });
        setTimeout(()=>{
            this.setState({data: VMData})
            this.setState({ loading: false })
            console.log('component did mount')
            console.log(this.state.loading)}, 2000)

            adalApiFetch(axios.get, url)
                .then(response=> {
                    console.log(((response.data)))
                    this.setState({data: JSON.parse(response.data)});
                    this.setState({loading: false});
                })
                .catch(error => {
                    console.log(error);
                    this.setState({error: error})
                })
    };

        //axios.get(url)
        //     .then(response => {
        //        console.log(((response.data)));
                /* this.setState({ data: JSON.parse(response.data) }); */
        //           this.setState({data: (JSON.parse(JSON.stringify(response.data).replace(/\s(?=\w+":)/g, "")))});
        //          this.setState({ loading: false });
        //        })
        //      .catch(error => {
        //        console.log(error);
        //        this.setState({error: true});
        //  })
    
    


    render() {
        const rowStyle2 = (row, rowIndex) => {  
            const style = {};
                if (rowIndex % 2 === 0) {
                    style.backgroundColor = '#F5F5F5';
                } else {
                    style.backgroundColor = '#FFFFFF';
                }
                return style;
        };

        let display;
        display=  <div><h2>Something Went Wrong</h2>

            if {this.state.error instanceof window.Response ? (
                <p>
                    <b>{this.state.error.status}</b> on <b>{this.error.url}</b>
                    <br />
                    <small>{this.state.error.statusText}</small>
                </p>
            ) : (
                <p>
                    <code>{this.state.error.toString()}</code>
                </p>
            )}</div>

            if (this.state.loading) {
                display = 
                    <LoadingOverlay
                        active={this.state.loading}
                        spinner
                        text='Loading data...'
                        >
                        <BootstrapTable
                            headerClasses="header-class"
                            striped
                            hover
                            keyField='Name'
                            data={this.state.data}
                            columns={this.state.columns}
                            filter={filterFactory()}
                            rowStyle={rowStyle2}
                        //  overlay={overlayFactory()}
                        />
                    </LoadingOverlay>;
                
                //  display = <img src={require('./giphy.gif')} />          
                } else {
                    display =
                        <BootstrapTable
                            headerClasses="header-class"
                            striped
                            hover
                            keyField='Name'
                            data={this.state.data}
                            columns={this.state.columns}
                            filter={filterFactory()}
                            rowStyle={rowStyle2}
                        />
                };
    

            return (
                <div className="container">
                    
                        <div className="text-center" 
                            style={{
                                marginTop: 50, flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                            }}>
                            <p ><font size="+2">Below you can find details of the available Virtual Machines. To turn off a specific virtual machine please use the respective toggle switch: </font></p>

                            {display}
                        </div>
                </div>
            );
    }
}
export default Table;



{/*
                                //<ToggleSwitch checked={this.state.checked} onchange={(value) => this.setState({ checked: value })} />                              
                                
                                <Button variant="success"
                                    onClick={() => {
                                        VMStart_(row.ResourceGroup, row.Name);
                                        this.refresh()
                                        console.log(row.State)
                                    }}
                                > On
                                </Button>
                                <Button variant="danger"
                                    onClick={() => {
                                        VMStop_(row.ResourceGroup, row.Name);
                                        console.log(row.State)
                                    }}
                                > Off
                                </Button>
                                */}
