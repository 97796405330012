import React, {Component} from 'react';
import './Home.css';
import NavigationBar from '../Components/NavigationBar.js';
import Footer from '../Components/Footer.js';
import Skills from '../Components/Skills.js';
import Projects from './Projects';


class Home extends Component {
        render() {
            return(
                <div>
                   <NavigationBar />
                   <br />
                   {
                    //TO DO: replace with jumbotron component
                    }
                    <div className="container">
                        <br></br>
                        <div className="jumbotron jumbotron-fluid">
                            <div className="container">
                                <h1 className="display-3">Are you ready for the digital age? <br></br></h1>
                                <h1 className="display-4">I can help you get there.</h1>
                                <p className="lead">My name is Michael Candreia and I am a Zurich based entrepreneur & freelancer skilled in various technologies including Web Development, Blockchain and Data Science. </p>  
                            </div>
                        </div>
                        <div align="center" className="container">
                        <Skills/>
                        </div>
                    </div>
                    
                    <Projects/>
                    <Footer />
                </div>
            );
        }
}
export default Home;